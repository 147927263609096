import {visibilityFilter} from "../../utils/VisibilityFilter.ts";
import {RootState} from "../../store";
import {SignInProfile} from "./types.ts";
import {createSelector} from "@reduxjs/toolkit";

const key = 'signInProfiles';

const selectIds = (state: RootState) => state[key].records.ids;
const selectRecordsById = (state: RootState) => state[key].records.byId;

const selectProfiles = createSelector([selectIds, selectRecordsById], (recordIds, recordsById) => {
    return recordIds.map((id: string) => {
        return recordsById[id];
    }) as SignInProfile[];
})

const selectProfile = createSelector([selectRecordsById], (recordsById) => {
    return (id: string) => {
        return recordsById[id] as SignInProfile;
    }
})

export const signInProfilesSelector = (state: RootState) => {
    return selectProfiles(state)
};

export const signInProfileSelector = (state: RootState, id: string) => {
    return selectProfile(state)(id);
};

export const activeSignInProfileSelector = (state: RootState) => {
    const collection = signInProfilesSelector(state);
    if (Array.isArray(collection) && collection.length > 0) {
        const found = collection.find(item => item.isActive === true)
        if (typeof found !== 'undefined') {
            return signInProfileSelector(state, found.id as string);
        }
    }
};

export const signInProfilesCountSelector = (state: RootState) => {
    return state[key].records.ids.length;
};

export const visibilityPropsSelector = (state: RootState) => {
    const items = signInProfilesSelector(state);

    const page = state[key].visibility.page;
    const limit = state[key].visibility.limit;

    const totalCount = state[key].records.totalCount;

    const keyword = state[key].visibility.keyword;

    const visibleItems = visibilityFilter(
        items as { id: string }[],
        page,
        limit,
        keyword
    );

    return {
        items: visibleItems,
        page: page,
        limit: limit,
        totalCount: totalCount,
    };
};
