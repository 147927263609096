import {Cache} from 'aws-amplify';
import {I18n} from '@aws-amplify/core';
import {notifySuccess} from "../../../components/ServiceResponseNotifications";
import {
    SignInProfile,
    VerbosityLevel
} from "../types.ts";

const key = 'pac-sign-in-profiles';

export const fetchRecords = async () => {

    const items = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    return items as SignInProfile[];

};

export const fetch = async (id: string) => {
    const items = Cache.getItem(key, {
        callback: () => {
            return [] as SignInProfile[];
        },
    }) as SignInProfile[];

    return items.find((a) => a.id === id);
};

export const add = async (payload: SignInProfile) => {
    const verbosity = await getVerbosityLevel()

    const record = {
        ...payload, ...{
            isDefault: false,
            createdAt: new Date().toISOString(),
        }
    } as SignInProfile;

    const signInOptions = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    let statusUpdated = signInOptions;

    // if the user selected to activate the new profile, iterate over all other profiles and change their active status to false
    if (record.isActive === true) {
        // change the language
        I18n.setLanguage(payload.language);

        statusUpdated = signInOptions.map((item: SignInProfile) => {
            if (item.isActive === true) {
                return {
                    ...item,
                    isActive: false
                };
            }
            return item;
        });
    }

    // push the newly created record to the array of profiles
    statusUpdated.push(record);

    //store the updated array back in the cache
    Cache.setItem(key, statusUpdated);


    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Sign-in Profile added');
    return record;

};

export const update = async (recordId: string, record: SignInProfile) => {
    const verbosity = await getVerbosityLevel()

    const updated = {
        ...record, ...{
            updatedAt: new Date().toISOString()
        }
    } as SignInProfile;

    const signInOptions = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const filtered = signInOptions.filter((a: SignInProfile) => a.id !== recordId);

    filtered.push(updated);
    Cache.setItem(key, filtered);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Sign In Options updated')
    return updated;

};

export const deleteRecord = async (recordId: string) => {
    const verbosity = await getVerbosityLevel()

    const signInOptions = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    // remove the item from the current array of options
    const filtered = signInOptions.filter((item: SignInProfile) => item.id !== recordId)

    // make the default item active
    const statusUpdated = filtered.map((item: SignInProfile) => {
        if (item.isDefault === true) {
            // change the language
            I18n.setLanguage(item.language);

            // make this item active
            return {
                ...item,
                isActive: true
            };
        }
        return item;
    });

    Cache.setItem(key, statusUpdated);

    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Sign-in profile deleted');

    return recordId;
};

export const deleteAllRecords = async () => {
    const verbosity = await getVerbosityLevel()

    const signInOptions = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const filtered = signInOptions.filter((item: SignInProfile) => item.isDefault === true)
    Cache.setItem(key, filtered);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('All custom sign-in profiles cleared');
};

export const fetchActiveRecord = async () => {

    const items = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const active = (items as SignInProfile[]).find((a) => a.isActive === true) as SignInProfile;
    return active
};

export const getVerbosityLevel = async () => {
    const activeRecord = await fetchActiveRecord();
    return activeRecord?.verbosity;
};
