import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';


import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@pac/platform-ui-components/style.css'


import {Notifications} from '@mantine/notifications';
import {AccountsContextProvider} from "./features/accounts/context/AccountsContext.tsx";
import {ApplicationsContextProvider} from "./features/applications/providers/ApplicationsContextProvider.tsx";
import {Suspense} from 'react';
import {routesConfig} from "./config/routes.tsx";
import {PlatformLoader} from '@pac/platform-ui-components';
import {MantineProvider} from '@mantine/core';
import {theme} from "./theme.ts";


// var cookies = document.cookie.split(";");
// var cookies = document.cookie;


const App = () => {

    // const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
    // const toggleColorScheme = (value?: ColorScheme) =>
    //     setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
    // <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
    // <ThemeProvider theme={{colorScheme}}>

    const router = createBrowserRouter(routesConfig);

    return (
        <MantineProvider theme={theme}>
            <Suspense fallback={<PlatformLoader message={'Loading application...Please, wait!'}/>}>
                <AccountsContextProvider>
                    <ApplicationsContextProvider>
                        <Notifications position="bottom-left" zIndex={2077}/>
                        <RouterProvider router={router}/>
                    </ApplicationsContextProvider>
                </AccountsContextProvider>
            </Suspense>
        </MantineProvider>
    )
};

// Hub.listen(/.*/, (hubData) => {
//     const { event, data } = hubData.payload;
//     console.debug('EVENT', event);
//     console.debug('DATA', data);

//     if (event === 'networkStatus') {
//         console.debug(`User has a network connection: ${data.active}`);
//     }
// });

export default App;