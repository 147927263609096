import {I18n} from '@aws-amplify/core';

import {
    Badge,
    Center,
    Table
} from '@mantine/core';
import {NextCloseButton} from "@pac/platform-ui-components";

export const ActionsControl = ({
                                   label,
                                   resourceCount,
                                   onClickHandler,
                                   disabled
                               }: {
    label: string,
    resourceCount: number,
    onClickHandler: () => void
    disabled?: boolean
}) => (
    <Table.Tr>
        <Table.Td>
            {I18n.get(label)}:
        </Table.Td>

        {resourceCount > 0 ? (
                <>
                    <Table.Td>
                        <Badge variant={'filled'}>{resourceCount}</Badge>
                    </Table.Td>
                    <Table.Td>
                        <Center>
                            <NextCloseButton
                                onClick={onClickHandler}
                                disabled={disabled}
                            >
                                {I18n.get('Clear')}
                            </NextCloseButton>
                        </Center>
                    </Table.Td>
                </>
            ) :

            <Table.Td colSpan={2}>
                <Badge variant={'filled'}>{resourceCount}</Badge>
            </Table.Td>

        }
    </Table.Tr>
);