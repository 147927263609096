import {changePassword} from "../reducers/recordsSlice.ts";
import {Credentials} from "../../credentials/types.ts";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {CognitoUser} from 'amazon-cognito-identity-js';
import {changePasswordResultSelector} from "../selectors.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {Account} from "../../accounts/types.ts";

export const useChangePassword = (account: Account) => {

    if (typeof account === 'undefined') {
        throw new Error('Account is required parameter');
    }

    const {user} = useAuth({account});

    const dispatch = useAppDispatch();

    const changePasswordResult = useAppSelector((state) =>
                                                    changePasswordResultSelector(state)
    );

    const handleChangePassword = (values: Credentials) => {
        dispatch(
            changePassword({
                               account,
                               cognitoUser: user as CognitoUser,
                               newPassword: values.new_password as string
                           })
        );
    };


    return {
        changePasswordResult,
        handleChangePassword,
    }


}