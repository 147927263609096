import {Outlet} from 'react-router-dom';

import {
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderToolbar,
} from '@pac/platform-ui-components';

import {
    ActionIcon,
    AppShell,
    Box,
    Card,
    Center,
    Container,
    ScrollArea,
    useMantineColorScheme,
} from '@mantine/core';
import PanasonicLogo from "../assets/images/panasonic-logo.png";
import {
    AiFillMoon,
    AiFillSun
} from "react-icons/ai";
import {PageFooter} from "./PageFooter.tsx";

import classes from './AppLayout.module.css';
import {useViewportSize} from '@mantine/hooks';


export const AppLayout = () => {
    const {
        height,
    } = useViewportSize();

    const headerHeight = 70;
    const footerHeight = 95;

    const {
        colorScheme,
        toggleColorScheme
    } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    const ColorSchemeIcon = () => (
        <ActionIcon
            mt={'lg'}
            variant="subtle"
            color={dark ? 'yellow' : 'blue'}
            onClick={() => toggleColorScheme()}
            title="Toggle color scheme"
        >
            {dark ? <AiFillSun size={'3.5em'}/> : <AiFillMoon size={'3.5em'}/>}
        </ActionIcon>
    );

    const toolbar = <PlatformHeaderToolbar
        tools={[<ColorSchemeIcon/>]}/>;

    const header = <PlatformHeader toolbar={toolbar}/>;

    return (
        <AppShell
            header={{height: headerHeight}}
            footer={{height: footerHeight}}
        >
            <AppShell.Header>
                {header}
            </AppShell.Header>

            <AppShell.Main>
                <Box className={classes.main} style={{height: height - headerHeight - footerHeight}}>
                    <ScrollArea h={'100%'}>
                        <Container className={classes.mainContainer}>
                            <Card>
                                <Card.Section>
                                    <Center className={classes.pageHeader}>
                                        <img
                                            src={PanasonicLogo}
                                            alt="Panasonic Avionics"
                                            className="logo"
                                        />
                                    </Center>
                                </Card.Section>

                                <Box className={classes.pageContent}>
                                    <Outlet/>
                                </Box>

                                <Card.Section className={classes.pageFooter}>
                                    <PageFooter/>
                                </Card.Section>
                            </Card>

                        </Container>
                    </ScrollArea>
                </Box>
            </AppShell.Main>

            <AppShell.Footer p={'md'}>
                <PlatformFooter/>
            </AppShell.Footer>

        </AppShell>
    );
};
