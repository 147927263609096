import {RootState} from "../../store";
import {Application} from "./types.ts";
import {createSelector} from "@reduxjs/toolkit";

const key = 'applications';


// reversing the order using the added ts
export const sortApplications = (applications: Application[], direction = 'asc') => {
    return applications.sort(function (first: Application, second: Application) {

        // if (typeof first.position === 'undefined') {
        //     return 1;
        // }
        //
        // if (typeof second.position === 'undefined') {
        //     return -1;
        // }
        //
        // if (first.position < second.position) {
        //     return -1;
        // }
        //
        // if (first.position > second.position) {
        //     return 1;
        // }

        // if (typeof first.createdAt === 'undefined') {
        //     return 1;
        // }
        //
        // if (typeof second.createdAt === 'undefined') {
        //     return -1;
        // }

        if (direction === 'desc') {
            if (first.createdAt < second.createdAt) {
                return 1;
            }

            if (first.createdAt > second.createdAt) {
                return -1;
            }
        }

        if (first.createdAt < second.createdAt) {
            return -1;
        }

        if (first.createdAt > second.createdAt) {
            return 1;
        }


        // names must be equal
        return 0;
    });
};


const selectIds = (state: RootState) => state[key].records.ids;
const selectRecordsById = (state: RootState) => state[key].records.byId;
const selectApplicationsCount = (state: RootState) => state[key].records.ids.length;


const selectApplications = createSelector([selectIds, selectRecordsById], (recordIds, recordsById) => {
    return recordIds.map((id: string) => {
        return recordsById[id];
    }) as Application[];
})

const selectDefaultApplication = createSelector([selectApplications], (applications) => {
    return applications.find((application: Application) => application.isDefault) as Application;
})

const selectActiveApplication = createSelector([selectApplications], (applications) => {
    return applications.find((application: Application) => application.isActive) as Application;
})


const selectSortedApplications = createSelector([selectApplications], (applications) => {
    return sortApplications(applications, 'desc');
})


export const recordsSelector = (state: RootState) => {
    return state[key].records;
};


export const recordSelector = (state: RootState, id: string) => {
    const records = recordsSelector(state);
    const loading = records.loading;
    const record = records.byId[id];
    return {
        record,
        loading
    } as { record: Application, loading: boolean }
};


export const applicationsSelector = (state: RootState) => {
    return selectSortedApplications(state);
};

export const applicationsCountSelector = (state: RootState) => {
    return selectApplicationsCount(state);
};

export const activeApplicationSelector = (state: RootState) => {
    return selectActiveApplication(state);
};

export const defaultApplicationSelector = (state: RootState) => {
    return selectDefaultApplication(state);
};

export const applicationsWithNoVersionPropertySelector = (state: RootState) => {
    const applications = applicationsSelector(state);
    return applications.filter((application: Application) => !application.version) as Application[];
};


export const fistNApplicationsSelector = (state: RootState, max = 1) => {
    const apps = applicationsSelector(state);
    return apps.slice(0, max) as Application[];
};

