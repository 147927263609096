import {useEffect} from 'react';
import {useSignInProfiles} from "../../sign-in-profiles/hooks/useSignInProfiles.ts";
import {I18n} from 'aws-amplify';
import {PlatformFieldset} from '@pac/platform-ui-components';
import {useAccounts} from "../../accounts/hooks/useAccounts.ts";
import {ApplicationsList} from "../components/ApplicationsList.tsx";


export const IndexPage = () => {

    const {
        handleFetchSignInProfiles,
        activeProfile
    } = useSignInProfiles();
    const showReturnTo = activeProfile?.showReturnTo;

    useEffect(() => {
        handleFetchSignInProfiles()
    }, [])

    const {accounts} = useAccounts()

    if (typeof showReturnTo !== 'undefined' && showReturnTo === false) {
        return null;
    }

    if (accounts.length > 0) {
        return (
            <PlatformFieldset legend={I18n.get('Return to') + ':'}>
                <ApplicationsList/>
            </PlatformFieldset>
        );
    }
};
