import {createContext} from 'react';
import {Application} from "../types.ts";

type ApplicationsContextType = {
    activeApplication: Application | null
}


export const ApplicationsContext = createContext<ApplicationsContextType>({
                                                                              activeApplication: null
                                                                          });

