import {I18n} from '@aws-amplify/core';
import {
    Box,
    Card,
    Center,
    Group
} from '@mantine/core';

import {AiOutlineClose} from 'react-icons/ai';
import {NextButton} from '@pac/platform-ui-components';
import {Application} from "../types.ts";
import {useApplications} from "../hooks/useApplications.ts";
import {useApplication} from "../hooks/useApplication.ts";
import classes from './ApplicationProfile.module.css';

export const ApplicationProfile = (
    {
        application,
    }: {
        application: Application,
    }) => {

    const {
        handleFetchApplications,
    } = useApplications();

    const {
        handleDeleteApplication,
    } = useApplication();

    const className = application.isActive === true ? [classes['profile'], classes['active']].join(' ') : [classes['profile'], classes['default']].join(' ');

    return (
        <Card shadow={'xs'} className={className}>
            <Card.Section>
                <Group justify={'space-between'}>
                    <Box p="sm">
                        {application.origin}
                    </Box>

                    <Center p={'sm'}>
                        {!application.isDefault ? <NextButton
                                variant={'outline'}
                                leftSection={<AiOutlineClose/>}
                                onClick={() => {
                                    handleDeleteApplication(application.id)
                                    setTimeout(() => {
                                        handleFetchApplications()
                                    }, 1000)
                                }}
                            >{I18n.get('Clear')}</NextButton> :
                            <NextButton disabled={true}>Default</NextButton>}
                    </Center>
                </Group>
            </Card.Section>
        </Card>
    );


};
