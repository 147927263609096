import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {Auth} from '@aws-amplify/auth';
import {Account} from "../../accounts/types.ts";
import {CognitoUser} from 'amazon-cognito-identity-js';

const entityKey = 'changePassword';

const initialState = {
    result: '',
    loading: false,
    error: '',
};

// genenrates pending, fulfilled and rejected
export const changePassword = createAsyncThunk(
    `${entityKey}/reset`,
    ({
         account,
         cognitoUser,
         newPassword
     }: {
        account: Account,
        cognitoUser: CognitoUser,
        newPassword: string
    }) => {
        Auth.configure({
                           userPoolId: account.cognitoUserPoolId,
                           userPoolWebClientId: account.appClientId,
                       });

        return Auth.completeNewPassword(cognitoUser, newPassword)
                   .then(
                       (response) => response
                   );
    }
);

const resetPasswordsSlice = createSlice(
    {
        name: entityKey,
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            // PASSWORD CHANGE
            builder.addCase(changePassword.pending, (state) => {
                state.loading = true;
            });

            builder.addCase(changePassword.fulfilled, (state, action) => {
                const {payload} = action;
                console.debug('CHANGE PASS PAYLOAD', payload);
                state.loading = false;
                state.result = 'success';
            });

            builder.addCase(changePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message as string;
            });
        },
    });

export default resetPasswordsSlice.reducer;
