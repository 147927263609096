import {stage} from "./stage.ts";

const config = {
    prd: {
        name: 'NEXT Cloud Login',
        host: 'https://login.nextcloud.aero',
        latestVersion: 14,
    },
    test: {
        name: 'NEXT Cloud Login',
        host: 'https://login-test.nextcloud.aero',
        latestVersion: 14,
    },
    dev: {
        name: 'NEXT Cloud Login',
        host: 'https://login-dev.nextcloud.aero',
        latestVersion: 14,
    },
    ide: {
        name: 'NEXT Cloud Login',
        host: process.env.VITE_HOST,
        latestVersion: 14,
    },
} as { [key: string]: any };

export default config[stage];
