import {Cache} from 'aws-amplify';
import {platform} from "../../../config/platform.ts";
import {Application} from "../types.ts";
import {moduleConfig} from "../config";

const APPLICATIONS_STORAGE_KEY = moduleConfig.localStorageKey;

const addDefaultApplication = () => {

    const url = new URL(platform.portal as string);
    const locationSearch = window.location.search;

    // delimiter looks like this - ?returnTo=https://myapp.nexcloud.aero/my-path;
    const delimiter = '?returnTo=' + url.origin + url.pathname;
    // console.debug('delimiter', delimiter);

    // returnToSearch looks like this - ?foo=bar&zet=zero;
    let returnToSearch = locationSearch.substring(delimiter.length);
    // console.debug('returnToSearch', returnToSearch);

    if (returnToSearch.charAt(0) === '?') {
        returnToSearch = returnToSearch.substring(1);
    }

    const application = {
        id: crypto.randomUUID(),
        version: moduleConfig.schemaVersion,
        returnToSearch: returnToSearch,
        host: url.host,
        hostname: url.hostname,
        origin: url.origin,
        pathname: url.pathname,
        port: url.port,
        protocol: url.protocol,
        isDefault: true,
        isActive: true,
        createdAt: new Date().toISOString(),
    } as Application;

    const payload = [application]
    console.debug('adding default return to  application', payload)

    try {
        // store
        Cache.setItem(APPLICATIONS_STORAGE_KEY, payload);
    } catch (e) {
        console.error(e);
    }
}


const applications = Cache.getItem(APPLICATIONS_STORAGE_KEY, {
    callback: () => {
        return [];
    }
});


if (applications.length === 0) {

    Cache.removeItem(APPLICATIONS_STORAGE_KEY);
    addDefaultApplication();

} else {

    // find default application
    const defaultApplication = applications.find((application: Application) => application.isDefault);

    if (!defaultApplication) {
        Cache.removeItem(APPLICATIONS_STORAGE_KEY);
        addDefaultApplication();
    }

}
