import {
    activeApplicationSelector,
    defaultApplicationSelector,
    recordSelector,
} from "../selectors";
import {
    deleteApplication,
    fetchApplication,
    findApplicationByUrl,
    saveApplication,
    updateApplication,
} from '../reducers/recordsSlice';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {Application} from "../types.ts";

export const useApplication = (applicationId?: string) => {

    const dispatch = useAppDispatch();
    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, applicationId ? applicationId : '')
    );


    // get default application
    const defaultApplication = useAppSelector((state: RootState) => defaultApplicationSelector(state));

    // get active application
    const activeApplication = useAppSelector((state: RootState) =>
                                                 activeApplicationSelector(state)
    );

    // handle fetch application with id
    const handleFetchApplication = (id: string) => {
        dispatch(fetchApplication(id));
    };

    // handle search for application by url properties
    const handleSearchByUrl = (url: URL) => {
        dispatch(findApplicationByUrl(url));
    };

    const handleSaveApplication = (application: Application) => {
        dispatch(saveApplication(application));
    };

    // handle update application
    const handleUpdateApplication = (id: string, record: Application) => {
        dispatch(updateApplication({
                                       id,
                                       record
                                   }));
    };

    const handleDeleteApplication = (id: string) => {
        dispatch(deleteApplication(id));
    };


    return {
        record,
        loading,
        defaultApplication,
        activeApplication,
        handleFetchApplication,
        handleSearchByUrl,
        handleSaveApplication,
        handleUpdateApplication,
        handleDeleteApplication,
    }
}
