import {Stack} from '@mantine/core';
import {ApplicationProfile} from "./ApplicationProfile.tsx";
import {useApplications} from "../hooks/useApplications.ts";
import {useEffect} from 'react';

export const ApplicationsList = () => {

    const {
        applications,
        loading,
        handleFetchApplications,
    } = useApplications();

    useEffect(() => {
        handleFetchApplications();
    }, [loading]);

    return (
        <Stack gap={'xs'}>
            {applications
                .map((application, index) => {

                    return (
                        <ApplicationProfile
                            key={index}
                            application={application}
                        />
                    );
                })}
        </Stack>
    );
};
