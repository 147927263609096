import {stage} from "./stage.ts";

type ConfigShape = {
    [key in 'prd' | 'test' | 'dev' | 'ide']: {
        login: string,
        portal: string,
    }
}


const config: ConfigShape = {
    prd: {
        login: 'https://login.nextcloud.aero',
        portal: 'https://portal.nextcloud.aero',
    },
    test: {
        login: 'https://login-test.nextcloud.aero',
        portal: 'https://portal-test.nextcloud.aero',
    },
    dev: {
        login: 'https://login-dev.nextcloud.aero',
        portal: 'https://portal-dev.nextcloud.aero',
    },
    ide: {
        // login: 'https://login-dev.nextcloud.aero',
        login: 'https://localhost:8080',
        portal: 'https://localhost:8081',
    },
};

export const platform = config[stage];
