import {
    Cache,
    I18n
} from 'aws-amplify';
import {
    SignInProfile,
    VerbosityLevel
} from "../types.ts";

const PROFILES_STORAGE_KEY = 'pac-sign-in-profiles';

const profiles = Cache.getItem(PROFILES_STORAGE_KEY, {
    callback: () => {
        return [];
    },
});


if (profiles.length === 0) {

    Cache.removeItem(PROFILES_STORAGE_KEY);

    // update version
    const profile = {
        id: `sign-in-profile-${Date.now()}`,
        language: 'en',
        showReturnTo: true,
        maxReturnTo: 1,
        passwordAndSso: false,
        verbosity: VerbosityLevel.LOW,
        isDefault: true,
        isActive: true,
        createdAt: new Date().toISOString(),
    } as SignInProfile;


    // store
    Cache.setItem(PROFILES_STORAGE_KEY, [profile]);

} else {
    // profiles already exist
    const activeProfile = profiles.find((profile: any) => profile.isActive === true);
    const language = activeProfile?.language || 'en';
    I18n.setLanguage(language);
}
