import {RootState} from "../../store";
import {Account} from "./types.ts";
import {createSelector} from "@reduxjs/toolkit";

const key = 'accounts';

const selectIds = (state: RootState) => state[key].records.ids;
const selectRecordsById = (state: RootState) => state[key].records.byId;

const selectAccounts = createSelector([selectIds, selectRecordsById], (recordIds, recordsById) => {
    return recordIds.map((id: string) => {
        return recordsById[id];
    }) as Account[];
})

const selectAccount = createSelector([selectRecordsById], (recordsById) => {
    return (id: string) => {
        return recordsById[id] as Account;
    }
})

const selectVisibleAccounts = createSelector([selectAccounts], (accounts) => {
    return accounts.filter((item) => {
        return item.isVisible === 'yes';
    });
})


export const accountsSelector = (state: RootState) => {
    return selectVisibleAccounts(state);
};

export const accountsLoadingSelector = (state: RootState) => {
    return state.accounts.records.loading;
};

export const accountSelector = (state: RootState, id: string) => {
    return selectAccount(state)(id);
};

export const accountsCountSelector = (state: RootState) => {
    return state.accounts.records.totalCount;
};

export const selectedLoginProfileSelector = (state: RootState) => {
    let keys = Object.keys(state.accounts.selection);

    if (Array.isArray(keys) && keys.length > 0) {
        return state.accounts.selection[keys[0]] as Account;
    }
};

export const accountsSelectionSelector = (state: RootState) => {
    return state.accounts.selection;
};

export const accountsSelectionLengthSelector = (state: RootState) => {
    let selection = accountsSelectionSelector(state);
    let keys = Object.keys(selection);
    return keys.length;
};
