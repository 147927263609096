import {ActionsControl} from './ActionsControl';
import {useApplications} from "../../applications/hooks/useApplications";

export const ApplicationsControl = ({type}: { type: 'default' | 'custom' }) => {

    const {
        defaultApplicationsCount,
        nonDefaultApplicationsCount,
        handleDeleteNonDefaultApplications
    } = useApplications();

    const label = type === 'default' ? 'Default Return-To Applications' : 'Custom Return-To Applications';
    const resourceCount = type === 'default' ? defaultApplicationsCount : nonDefaultApplicationsCount;


    return (
        <ActionsControl
            label={label}
            resourceCount={resourceCount}
            onClickHandler={handleDeleteNonDefaultApplications}
            disabled={type === 'default'}
        />
    );
};
