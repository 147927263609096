import {
    applicationsCountSelector,
    applicationsSelector,
} from "../selectors";
import {
    deleteAllApplications,
    deleteApplication,
    fetchApplications,
    swapPositions,
} from '../reducers/recordsSlice';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {Application} from "../types.ts";

export const useApplications = () => {

    const dispatch = useAppDispatch();

    const loading = useAppSelector((state: RootState) => state.applications.loading)

    const applications = useAppSelector((state: RootState) =>
                                            applicationsSelector(state));
    const applicationsCount = useAppSelector((state: RootState) =>
                                                 applicationsCountSelector(state));

    // get all default applications
    const defaultApplications = applications.filter((app: Application) => app.isDefault === true) as Application[];
    const defaultApplicationsCount = defaultApplications.length;

    // get all non default applications
    const nonDefaultApplications = applications.filter((app: Application) => app.isDefault === false) as Application[];
    const nonDefaultApplicationsCount = nonDefaultApplications.length;


    const handleFetchApplications = () => {
        dispatch(fetchApplications());
    };


    const handleDeleteAllApplications = () => {
        dispatch(deleteAllApplications());
    };

    const handleDeleteNonDefaultApplications = () => {

        // delete all non default applications
        const ids = nonDefaultApplications.map((application) => application.id);
        ids.forEach((id) => {
            dispatch(deleteApplication(id))
        });
        return true
    };

    const handleSwapPositions = () => {
        dispatch(swapPositions());
    };

    const handleDeleteApplicationsWithNoVersionProperty = () => {
        const ids = applications.filter((app: Application) => app.isDefault === false && (!app.version))
                                .map((app: Application) => app.id);
        ids.forEach((id: string) => {
            dispatch(deleteApplication(id))
        });
    };


    return {
        applications,
        applicationsCount,
        loading,
        defaultApplications,
        defaultApplicationsCount,
        nonDefaultApplications,
        nonDefaultApplicationsCount,
        handleFetchApplications,
        handleDeleteAllApplications,
        handleSwapPositions,
        handleDeleteNonDefaultApplications,
        handleDeleteApplicationsWithNoVersionProperty
    }
}
