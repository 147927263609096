import {Cache} from 'aws-amplify';

import {notifySuccess} from "../../../components/ServiceResponseNotifications";
import config from "../../../config";
import {Application} from "../types.ts";
import {getVerbosityLevel} from "../../sign-in-profiles/services/SignInProfilesService.ts";
import {VerbosityLevel} from "../../sign-in-profiles/types.ts";
import {moduleConfig} from "../config";

const key = moduleConfig.localStorageKey


export const isAllowedHostname = (hostname: string) => {
    let out = false;

    config.allowedReturnToDomains.forEach((domainName) => {
        if (hostname.indexOf(domainName) !== -1) {
            out = true;
        }
    });

    return out;
};

export const fetchRecords = async () => {

    console.debug('in fetch applications')
    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];

    return applications.filter((item: Application) => {
        return item.version === moduleConfig.schemaVersion;
    });

};


export const fetch = async (id: string) => {
    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];
    return applications.find((a: Application) => a.id === id);
};

export const add = async (application: Application) => {
    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];

    const verbosity = await getVerbosityLevel()

    if (!isAllowedHostname(application.hostname as string)) {
        throw new Error('Operation is not allowed');
    }

    const existing = applications.find((item: Application) => item.origin === application.origin);
    if (existing) {
        return;
    }

    application.id = crypto.randomUUID();
    application.createdAt = new Date().toISOString();

    // const filtered = applications.filter((item: Application) => item.host !== application.host);
    const filtered = applications.filter((item: Application) => item.isDefault);
    const updated = filtered.map((item: Application) => {
        item.isActive = false;
        return item;
    });
    updated.push(application);

    Cache.setItem(key, updated);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Application added');
    return application;

};

export const update = async (_: string, application: Application) => {
    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];

    const verbosity = await getVerbosityLevel()

    application.createdAt = new Date().toISOString();

    const index = applications.findIndex((a: Application) => a.id === application.id);
    applications[index] = application;

    Cache.setItem(key, applications);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Application updated');
    return application;
};

export const deleteRecord = async (id: string) => {
    const verbosity = await getVerbosityLevel()

    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];


    const filtered = applications.filter((item: Application) => item.id != id);

    const updated = filtered.map((item: Application) => {
        item.isActive = true;
        return item;
    });

    console.debug('updated', updated);

    Cache.setItem(key, updated);

    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Application deleted');
    return id
};

export const deleteAllRecords = async () => {
    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];

    const verbosity = await getVerbosityLevel()

    // filter out all non-default records
    const defaultApplication = applications.find((item: Application) => item.isDefault === true);
    if (!defaultApplication) {
        throw new Error('No default application found');
    }
    defaultApplication.isActive = true;
    Cache.setItem(key, [defaultApplication]);

    verbosity === VerbosityLevel.MEDIUM && notifySuccess('All applications deleted');
};

export const deleteApplicationsWithOldVersionOrNoVersion = async () => {
    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];

    const filtered = applications.filter((item: Application) => {
        if (!item.version) {
            return false;
        }
        return item.version === moduleConfig.schemaVersion;
    });

    Cache.setItem(key, filtered);
    return filtered;
};


export const markApplicationActive = (application: Application) => {
    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];
    applications.forEach((item: Application) => {
        item.isActive = item.id === application.id;
    });
    Cache.setItem(key, applications);
    return applications;
};

export const swap = async () => {
    // const applications = await fetchRecords();
    const promises = [] as any[];
    await Promise.all(promises);
    return fetchRecords();
};
