import {
    Navigate,
    useParams
} from 'react-router-dom';

import {PlatformLoader} from "@pac/platform-ui-components";
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {useRedirectUrl} from "../../../hooks/useRedirectUrl.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useApplications} from "../../applications/hooks/useApplications.ts";
import {useAccounts} from "../../accounts/hooks/useAccounts.ts";
import {useEffect} from "react";
import {Account} from "../../accounts/types.ts";
import {Application} from "../../applications/types.ts";
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {NEXTUser} from "../../users/types.ts";
import {useApplication} from "../../applications/hooks/useApplication.ts";


export const IndexPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);


    const {
        accounts,
        handleFetchAccounts: fetchAccounts
    } = useAccounts();

    useEffect(() => {
        fetchAccounts()
    }, [])

    const {
        applicationsCount,
        handleFetchApplications
    } = useApplications()

    const {
        defaultApplication,
        activeApplication
    } = useApplication()

    useEffect(() => {
        handleFetchApplications()
    }, [])

    const {
        profile,
        session
    } = useAuth({account});

    if (
        typeof session === 'undefined' ||
        session === null ||
        !session.isValid()
    ) {
        return <PlatformLoader message="Loading session... Please wait"/>;
    }


    if (typeof profile === 'undefined' || profile === null) {
        return <PlatformLoader message="Loading Profile... Please wait"/>;
    }


    if (!Array.isArray(accounts) || accounts.length === 0) {
        return <PlatformLoader message="Loading accounts... Please wait"/>;
    }

    if (applicationsCount === 0) {
        return <PlatformLoader message="Loading applications count... Please wait"/>;
    }

    if (typeof activeApplication === 'undefined' || activeApplication === null) {
        return <PlatformLoader message="Loading applications... Please wait"/>;
    }

    if (typeof account === 'undefined' || account === null) {
        return <PlatformLoader message="Loading account... Please wait"/>;
    }

    const application = activeApplication ? activeApplication : defaultApplication

    return <CheckAndRedirect account={account} application={application} profile={profile} session={session}/>;

};


export const CheckAndRedirect = ({
                                     account,
                                     application,
                                     profile,
                                     session
                                 }: {
    account: Account,
    application: Application,
    profile: NEXTUser,
    session: CognitoUserSession
}) => {


    const redirectUrl = useRedirectUrl({
                                           application,
                                           session
                                       });

    const {email} = session.getIdToken().payload;

    // this does not work fo users with different email addresses
    const enabledCheck = false
    if (enabledCheck) {
        if (
            account.emailAddress?.toLowerCase() !== email.toLowerCase()
            // user?.attributes.email.toLowerCase()
        ) {
            return <Navigate to={`/accounts/${account.id}/credentials`}/>;
        }
    }


    if (typeof profile !== 'undefined' && profile !== null) {
        if (
            typeof profile.tc === 'undefined' ||
            profile.tc.accepted === false
        ) {
            return (
                <Navigate to={`/accounts/${account.id}/user-agreements/acceptance`}/>
            );
        }
    }

    window.location.assign(redirectUrl);
    return null;

}